import React, { useState, useEffect } from 'react';
import './App.css';

const CELL_SIZE = 132;

const MemoryMatchGame = () => {
  const [rows, setRows] = useState(0);
  const [columns, setColumns] = useState(0);
  const [score, setScore] = useState(0);
  const [level, setLevel] = useState(1);
  const [meatsToFind, setMeatsToFind] = useState(1);
  const [meatPositions, setMeatPositions] = useState(new Set());
  const [selectedPositions, setSelectedPositions] = useState(new Set());
  const [showMeat, setShowMeat] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [win, setWin] = useState(false);

  const [overlayPosition, setOverlayPosition] = useState({ x: 10, y: 10 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  const totalCells = rows * columns;

  const updateGridSize = () => {
    const newColumns = Math.floor(window.innerWidth / CELL_SIZE);
    const newRows = Math.floor(window.innerHeight / CELL_SIZE);
    setRows(newRows);
    setColumns(newColumns);
  };

  // Function to randomly set a fraction of the cells as meat
  const setRandomMeatPositions = (fraction = 0.1) => {
    if (totalCells <= 0) return;
    const numMeats = Math.floor(totalCells * fraction);
    const newMeatPositions = new Set();
    while (newMeatPositions.size < numMeats) {
      newMeatPositions.add(Math.floor(Math.random() * totalCells));
    }
    setMeatPositions(newMeatPositions);
  };

  // Initial state with a fraction of cells as meat, to be called on load or game-over
  const initializeGame = () => {
    setScore(0);
    setLevel(1);
    setMeatsToFind(1);
    setGameOver(false);
    setWin(false);
    setGameStarted(false); // Game hasn't started
    setSelectedPositions(new Set());
    setShowMeat(false);
    setRandomMeatPositions(0.1); // Set a fraction of cells as meat for pre-game display
  };

  // Starts the game at level 1 with the appropriate number of meat items
  const startGame = () => {
    setGameStarted(true);
    setScore(0);
    setLevel(1);
    setMeatsToFind(1);
    setGameOver(false);
    setWin(false);
    resetMeatPositionsForLevel(1); // Start at level 1 with 1 meat
  };

  // Set meat positions based on the current level or a passed value
  const resetMeatPositionsForLevel = (meatsCount) => {
    if (totalCells <= 0) return;
    const newMeatPositions = new Set();
    while (newMeatPositions.size < meatsCount) {
      newMeatPositions.add(Math.floor(Math.random() * totalCells));
    }
    setMeatPositions(newMeatPositions);
    setShowMeat(true); // Show the meat for a reveal period

    // Hide meat after 2 seconds
    setTimeout(() => {
      setShowMeat(false);
    }, 2000);
  };

  // Handle cell clicks during gameplay
  const handleCellClick = (index) => {
    if (gameOver || !gameStarted || showMeat || win) return;

    if (meatPositions.has(index) && !selectedPositions.has(index)) {
      const newSelectedPositions = new Set(selectedPositions);
      newSelectedPositions.add(index);
      setSelectedPositions(newSelectedPositions);
      setScore((prevScore) => prevScore + 10);

      if (newSelectedPositions.size === meatPositions.size) {
        // Player found all meats
        if (meatsToFind >= totalCells) {
          // All cells had meats
          setWin(true);
        } else {
          // Move to the next level
          const nextMeatsToFind = meatsToFind + 1;
          setLevel((prevLevel) => prevLevel + 1);
          setMeatsToFind(nextMeatsToFind);
          setSelectedPositions(new Set());
          resetMeatPositionsForLevel(nextMeatsToFind);
        }
      }
    } else {
      // Incorrect guess
      setGameOver(true);
      setGameStarted(false); // Stop the game
      setRandomMeatPositions(0.1); // Set fraction of cells as meat in game-over state
    }
  };

  // Restart the game after game-over, restoring initial level
  const resetGame = () => {
    setGameOver(false);
    setWin(false);
    setSelectedPositions(new Set());
    setScore(0);
    setLevel(1);
    setMeatsToFind(1);
    setGameStarted(true);
    resetMeatPositionsForLevel(1); // Restart at level 1 with 1 meat
  };

  useEffect(() => {
    updateGridSize();
    window.addEventListener('resize', updateGridSize);
    return () => window.removeEventListener('resize', updateGridSize);
  }, []);

  useEffect(() => {
    if (rows > 0 && columns > 0) {
      initializeGame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, columns]);

  const handleDragStart = (e) => {
    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;
    setIsDragging(true);
    setDragStart({ x: clientX - overlayPosition.x, y: clientY - overlayPosition.y });
  };
  
  const handleDragMove = (e) => {
    if (isDragging) {
      const clientX = e.touches ? e.touches[0].clientX : e.clientX;
      const clientY = e.touches ? e.touches[0].clientY : e.clientY;
      setOverlayPosition({
        x: clientX - dragStart.x,
        y: clientY - dragStart.y,
      });
    }
  };
  
  const handleDragEnd = () => {
    setIsDragging(false);
  };

  return (
    <div className="game-container" onMouseMove={handleDragMove} onMouseUp={handleDragEnd}>
      <div
        className="overlay"
        style={{
          left: `${overlayPosition.x}px`,
          top: `${overlayPosition.y}px`,
          cursor: isDragging ? 'grabbing' : 'grab',
        }}
        onMouseDown={handleDragStart}
        onMouseMove={handleDragMove}
        onMouseUp={handleDragEnd}
        onTouchStart={handleDragStart}  // For touch devices
        onTouchMove={handleDragMove}   // For touch devices
        onTouchEnd={handleDragEnd}     // For touch devices
      >
        <p>LEVEL: {level}</p>
        <p>HAM TO FIND: {meatsToFind}</p>
        <p>SCORE: {score}</p>
        {gameOver && <p style={{ color: 'red' }}>GAME OVER!</p>}
        {win && <p style={{ color: 'green' }}>You Win! Click Restart to play again.</p>}
        <button
          onClick={gameOver || gameStarted ? resetGame : startGame}
          className="start-button"
        >
          {gameOver || gameStarted ? "Restart" : "Start"}
        </button>
      </div>
      <div
        className="grid-container"
        style={{
          gridTemplateColumns: `repeat(${columns}, ${CELL_SIZE}px)`,
          gridTemplateRows: `repeat(${rows}, ${CELL_SIZE}px)`,
          cursor: !showMeat && gameStarted && !gameOver && !win ? 'pointer' : 'default',
        }}
      >
        {Array.from({ length: totalCells }).map((_, index) => (
          <div
            className={`grid-item ${selectedPositions.has(index) ? "selected" : ""}`}
            key={index}
            onClick={() => handleCellClick(index)}
          >
            <img
              src={
                meatPositions.has(index) && (!gameStarted || showMeat || selectedPositions.has(index))
                  ? "/meat.svg"
                  : "/bone.svg"
              }
              alt={meatPositions.has(index) ? "Meat" : "Bone"}
              className={`item-image ${
                meatPositions.has(index) && (!gameStarted || showMeat || selectedPositions.has(index))
                  ? "meat-image"
                  : "bone-image"
              }`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MemoryMatchGame;